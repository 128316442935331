import { Autocomplete, TextField } from '@mui/material';
import { listPagedTeamProfiles } from 'actions/team/listPagedTeamProfiles';
import { transferMembersToDifferentTeam } from 'actions/user/transferMembersToDifferentTeam';
import { TYPE_AT_LEAST_TEXT } from 'components/Autocomplete';
import ConfirmationModal from 'components/ConfirmationModal';
import Typography from 'components/Typography';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { ITeamsState } from 'pages/teams/teamsState';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities/index';

const MINIMUM_SEARCH_LENGTH = 2;
const PROSPECTIVE_TEAM_STATE = 1;

type ITeamTransferModalProps = {
    onClose: () => void;
    selectedMembers: string[];
};
const TeamTransferModal = ({ onClose, selectedMembers }: ITeamTransferModalProps) => {
    const dispatch = useThunkDispatch();
    const { teams } = useSelector((state: AppStore) => ({
        loading: hasApiActivity(state),
        teams: state.pagedTeamProfiles.results ?? [],
    }));
    const [teamToTransferTo, setTeamToTransferTo] = useState<string>('');
    const [inputValue, setInputValue] = useState<string>('');

    const handleTeamStructure = () =>
        teams.map((t) => ({
            id: t.team?.teamId,
            name: t.team?.name,
        }));

    const options = hasValue(teams) ? handleTeamStructure() : [];
    const isOneMember =
        (Array.isArray(selectedMembers) && selectedMembers.length === 1) ||
        !Array.isArray(selectedMembers)
            ? true
            : false;

    const onYesClick = useCallback(() => {
        dispatch(transferMembersToDifferentTeam(selectedMembers, teamToTransferTo));
        onClose();
    }, [dispatch, onClose, selectedMembers, teamToTransferTo]);

    useEffect(() => {
        if (hasValue(inputValue) && (inputValue ?? '').length > MINIMUM_SEARCH_LENGTH) {
            dispatch(
                listPagedTeamProfiles({
                    includeArchived: false,
                    orderings: [],
                    page: 0,
                    pageSize: 10,
                    search: inputValue,
                    teamStateFilters: [PROSPECTIVE_TEAM_STATE],
                } as ITeamsState)
            );
        }
    }, [dispatch, inputValue]);

    return (
        <ConfirmationModal
            body={
                <React.Fragment>
                    <Typography variant="body2">
                        Please select which team you would like{' '}
                        {isOneMember ? 'this member' : 'these members'} to transfer to. Please note
                        that this team must be in <b>prospect</b> status.
                    </Typography>
                    <br />
                    <Autocomplete
                        getOptionLabel={(option) => option.name ?? ''}
                        onChange={(_, newValue) => {
                            setTeamToTransferTo(newValue?.id ?? '');
                        }}
                        onInputChange={(_, newValue) => {
                            setInputValue(newValue);
                        }}
                        options={options}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search Prospective Teams"
                                placeholder={TYPE_AT_LEAST_TEXT}
                            />
                        )}
                        value={options.find((option) => option.id === teamToTransferTo) ?? null}
                    />
                </React.Fragment>
            }
            noButtonText="Cancel"
            onNoClick={onClose}
            onYesClick={onYesClick}
            title="Transfer Team"
            yesButtonText="Submit"
        />
    );
};

export default hot(module)(TeamTransferModal);
